import { API_ROUTES } from "./configuration"; // Importa las rutas de tu API
import axios from "axios";

const REACT_APP_PASSWORD_LOGINPAGE = process.env.REACT_APP_PASSWORD_LOGINPAGE_2;
const REACT_APP_CORREO_LOGINPAGE = process.env.REACT_APP_CORREO_LOGINPAGE;

const axiosWithAuth = (token, Url) => {
  return axios.create({
    baseURL: Url,
    headers: {
      Authorization: `Bearer ${token}`, // Configura el header Authorization con el token Bearer
    },
  });
};

const axiosWithAuthPost = (token, Url) => {
  return (method, endpoint, data) => {
    return axios({
      method: method,
      url: `${Url}${endpoint}`,
      baseURL: Url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data, // Datos a enviar en la solicitud (para métodos POST, PUT, etc.)
    });
  };
};

export const getProducts = async (token, Filtros = "") => {
  const axiosInstance = axiosWithAuth(token);
  try {
    const response = await axiosInstance.get(API_ROUTES.PRODUCTS + Filtros);
    return response.data;
  } catch (error) {
    //console.error("Error al obtener datos con token:", error);
    throw new Error("Failed to fetch data with token");
  }
};

export const getProductId = async (token, Id) => {
  const axiosInstance = axiosWithAuth(token);
  try {
    const response = await axiosInstance.get(API_ROUTES.PRODUCTS + "/" + Id);
    return response.data;
  } catch (error) {
    //console.error("Error al obtener datos con token:", error);
    throw new Error("Failed to fetch data with token");
  }
};

export const loginPage = async () => {
  const postData = {
    correo: REACT_APP_CORREO_LOGINPAGE.toString(),
    password: REACT_APP_PASSWORD_LOGINPAGE,
  };

  try {
    const response = await axios.post(
      `${API_ROUTES.API}autenticacion/page`,
      postData
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const loginUsuario = async (usuario, password) => {
  const requestData = {
    correo: usuario,
    password: password,
  };

  try {
    const response = await axios.post(
      API_ROUTES.AUTENTICATION + "/",
      requestData
    );

    return response.data;
  } catch (error) {
    return "-1";
  }
};

export const createUsuario = (usuario, password, nombre, token) => {
  const postData = {
    correo: usuario,
    password: password,
    nombre: nombre,
  };

  const authRequest = axiosWithAuthPost(token, API_ROUTES.API);
  return authRequest("POST", "usuarios", postData);
};

export const modificaUsuario = (user, token) => {
  const authRequest = axiosWithAuthPost(token, API_ROUTES.API);
  return authRequest("PUT", "usuarios", user);
};

export const createCheckout = async (usuarioLocal, carrito) => {
  const requestData = {
    usuarioLocal,
    carrito,
  };

  try {
    const response = await axios.post(
      API_ROUTES.CHECKOUT + "/crearcheckout",
      requestData
    );

    return response.data;
  } catch (error) {
    return "-1";
  }
};

export const GetCheckout = async (id) => {
  try {
    const response = await axios.get(
      API_ROUTES.CHECKOUT + "/successVenta/" + id
    );

    return response.data.ventaConUsuario.idUsuario.nombre;
  } catch (error) {
    return "-1";
  }
};
