const REACT_APP_URL_API = process.env.REACT_APP_URL_API;

const API_URL = REACT_APP_URL_API;

//

//"https://api-e-commerce-maderas-con-amor-com-dif1.vercel.app/api/v1"; // URL base de tu API

export const API_ROUTES = {
  API: `${API_URL}/`,
  PRODUCTS: `${API_URL}/productos`,
  USERS: `${API_URL}/usuarios`,
  AUTENTICATION: `${API_URL}/autenticacion`,
  CHECKOUT: `${API_URL}/checkout`,
};
