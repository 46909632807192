import React, { createContext, useState, useEffect } from "react";
import { loginPage } from "./../API/utilities";
export const TokenContext = createContext();
const nuevoUsuario = {
  user: {
    apellidos: "",
    calle: "",
    ciudad: "",
    colonia: "",
    correo: "",
    códigoPostal: "",
    fechaNacimiento: "",
    noExterior: "",
    noInterior: "",
    nombre: "",
    teléfono: "",
    referencia: "",
    _id: "",
    genero: "",
  },
};

export const TokenProvider = ({ children }) => {
  const [token, setToken] = useState(""); // Inicializa el token como null
  const [products, setProducts] = useState([]);
  const [usuario, setUsuario] = useState(nuevoUsuario);
  const [itemsCarrito, setItemsCarrito] = useState(0);

  // Función para actualizar el token
  const updateToken = async () => {
    var Token = await loginPage();
    setToken(Token);
  };

  // Método para agregar un producto
  const addProduct = (newProduct) => {
    const existingProductIndex = products.findIndex(
      (product) => product._id === newProduct._id
    );

    if (existingProductIndex !== -1) {
      // Si el producto ya existe en el carrito, actualizamos la cantidad
      const updatedProducts = products.map((product, index) => {
        if (index === existingProductIndex) {
          return {
            ...product,
            CantidadPedido: (product.CantidadPedido || 1) + 1, // Incrementar la cantidad en +1 o establecerla en 1 si no existe
          };
        }
        return product;
      });

      setProducts(updatedProducts);
    } else {
      // Si el producto no existe, lo agregamos al carrito
      setProducts([...products, { ...newProduct, CantidadPedido: 1 }]);
    }
    setItemsCarrito(itemsCarrito + 1);
  };

  //Elimina del Carrito
  const deleteItem = (product) => {
    const existingProductIndex = products.findIndex(
      (p) => p._id === product._id
    );

    if (existingProductIndex !== -1) {
      const updatedProducts = [...products];

      if (updatedProducts[existingProductIndex].CantidadPedido > 1) {
        // Si la cantidad es mayor a 1, reducimos en 1 la cantidad
        updatedProducts[existingProductIndex].CantidadPedido -= 1;
      } else {
        // Si la cantidad es 1 o menos, eliminamos el producto del carrito
        updatedProducts.splice(existingProductIndex, 1);
      }

      setProducts(updatedProducts);
      saveToLocalStorage("products", updatedProducts);
      setItemsCarrito(itemsCarrito - 1);
    }
  };

  const CleanCart = () => {
    setItemsCarrito(0);
    setProducts([]);
  };

  const CleanUser = () => {
    actualizarUsuario(nuevoUsuario);
    saveToLocalStorage("user", nuevoUsuario);
  };

  // Método para actualizar usuario
  const actualizarUsuario = (usuario) => {
    setUsuario(usuario);
  };

  const saveToLocalStorage = (key, value) => {
    try {
      const serializedValue = JSON.stringify(value);

      localStorage.setItem(key, serializedValue);
    } catch (error) {
      //console.error("Error saving to localStorage:", error);
    }
  };

  const getFromLocalStorage = (key) => {
    try {
      const serializedValue = localStorage.getItem(key);
      const respuesta =
        serializedValue === null ? null : JSON.parse(serializedValue);

      if (key == "user" && respuesta == null) return nuevoUsuario;
      else if (key == "products" && respuesta == null) return [];
      else return respuesta;
    } catch (error) {
      //console.error("Error getting from localStorage:", error);
      return null;
    }
  };

  useEffect(() => {
    if (!(usuario.user._id === "")) saveToLocalStorage("user", usuario);
  }, [usuario]);

  useEffect(() => {
    if (products.length !== 0) saveToLocalStorage("products", products);
  }, [products]);

  useEffect(() => {
    const iniciarSesion = async () => {
      try {
        const respuesta = await loginPage();
        // Aquí puedes manejar la respuesta exitosa, por ejemplo, almacenar el token en el estado global, redireccionar, etc.

        setToken(respuesta.Token);
      } catch (error) {
        // Manejar errores de inicio de sesión, por ejemplo, mostrar un mensaje de error al usuario
        //console.error("Error en inicio de sesión:", error);
      }
    };

    iniciarSesion();
    const retrievedUser = getFromLocalStorage("user");

    if (!(retrievedUser.user._id === "")) {
      setUsuario(retrievedUser);
    }

    const retrievedCart = getFromLocalStorage("products");

    if (retrievedCart.length > 0) {
      setProducts(retrievedCart);
      const totalItems = retrievedCart.reduce((total, producto) => {
        return total + producto.CantidadPedido;
      }, 0);
      setItemsCarrito(totalItems);
    }
  }, []);

  return (
    <TokenContext.Provider
      value={{
        token,
        updateToken,
        addProduct,
        products,
        itemsCarrito,
        deleteItem,
        usuario,
        actualizarUsuario,
        saveToLocalStorage,
        CleanCart,
        CleanUser,
        nuevoUsuario,
      }}
    >
      {children}
    </TokenContext.Provider>
  );
};
