import React from "react";
import { Footer, Navbar } from "../components";
const AvisoPage = () => {
  return (
    <>
      <Navbar />
      <div className="container my-3 py-3 px-5">
        <h1 className="text-center">Aviso de Privacidad</h1>
        <hr />
        <div>
          <p className="lead text-justify">
            Este Aviso de Privacidad establece cómo Desarrollo Digital de
            Innovación Bosch, operador del sitio web damcreation.com, utiliza y
            protege cualquier información que usted proporcione al utilizar este
            sitio web.
          </p>

          <p className="lead text-justify">
            La información personal que recopilamos incluye nombres, direcciones
            de envío y números de teléfono. Esta información se recopila a
            través de un formulario en nuestra página web.
          </p>

          <p className="lead text-justify">
            Utilizamos cookies para almacenar información sobre los productos
            vistos y el nombre del usuario con el propósito de mejorar la
            experiencia de navegación en nuestro sitio web.
          </p>

          <p className="lead text-justify">
            Los usuarios tienen la opción de darse de baja o eliminar su
            información personal enviándonos un correo electrónico.
          </p>

          <p className="lead text-justify">
            Es responsabilidad del usuario proporcionar información precisa y
            completa. En caso de proporcionar una dirección incorrecta y el
            paquete haya sido enviado, el usuario será responsable de cualquier
            cargo adicional incurrido para enviar el paquete correctamente.
          </p>

          <p className="lead text-justify">
            Desarrollo Digital de Innovación Bosch se compromete a asegurar que
            su información esté segura. No compartimos su información personal
            con terceros, a menos que sea necesario para el envío de sus
            productos. Los envíos de pedidos se realizan a través de una empresa
            de envíos externa y no somos responsables por retrasos en la
            entrega.
          </p>

          <p className="lead text-justify">
            Este aviso de privacidad está sujeto a cambios. Se recomienda
            revisarlo periódicamente para estar informado sobre cómo protegemos
            la información que nos proporciona.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AvisoPage;
