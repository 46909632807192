import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { TokenContext } from "./../../Context/TokenContext";

function CardResumen({ Proceso = 1 }) {
  const { products } = useContext(TokenContext);

  let subtotal = 0;
  let totalItems = 0;
  products.map((item) => {
    return (subtotal += item.precio * item.CantidadPedido);
  });

  products.map((item) => {
    totalItems = totalItems + item.CantidadPedido;
    return totalItems;
  });
  return (
    <>
      <div className="col-md-4">
        <div className="card mb-4">
          <div className="card-header py-3 bg-black text-white">
            <h5 className="mb-0">Resumen de tu pedido</h5>
          </div>
          <div className="card-body">
            <ul className="list-group list-group-flush">
              <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                Total de Artículos ({totalItems})
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                <div>
                  <strong>Monto total</strong>
                </div>
                <span>
                  <strong>${subtotal.toFixed(2)}</strong> MXN
                </span>
              </li>
            </ul>

            {Proceso == 1 ? (
              <Link
                to="/checkout"
                className="btn btn-lg btn-block btn-secondary-dam text-secondary-dam hover-white text-wrap"
              >
                Ingresar domicilio de envío
              </Link>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CardResumen;
