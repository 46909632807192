import React from "react";
import { Footer, Navbar } from "../components";
const AboutPage = () => {
  return (
    <>
      <Navbar />
      <div className="container my-3 py-3 px-5">
        <h1 className="text-center">Acerca de nosotros</h1>
        <hr />
        <p className="lead text-justify">
          En nuestra tienda, fusionamos la artesanía tradicional con la
          innovación moderna para llevar a tu hogar productos únicos y
          elegantes. <br /> Especializados en el corte y grabado láser de
          materiales como acero y madera, nos dedicamos a crear piezas que no
          solo decoran, sino que también cuentan historias. Queremos ser parte
          de tus momentos más especiales, agregando un toque de distinción y
          calidez a cada rincón de tu hogar.
        </p>
        <p className="lead text-justify">
          Estámos comprometidos con la idea de hacer crecer esta marca, la
          pasión por el diseño y la calidad se refleja en cada producto que
          ofrecemos. Con dedicación y esfuerzo, hemos construido un equipo
          apasionado, que comparten la visión de llevar la belleza y la
          elegancia a través de nuestras creaciones.
        </p>
        <p className="lead text-justify">
          En cada detalle, desde los cortes y los delicados grabados hasta la
          elección cuidadosa de los materiales, nos esforzamos por ofrecer
          calidad y refinamiento. Creemos que la belleza está en la simplicidad
          bien ejecutada, y eso es lo que buscamos transmitir en cada artículo
          que sale de nuestro taller.
        </p>
        <p className="lead text-justify">
          Nuestra misión va más allá de simplemente ofrecer productos. Queremos
          ser parte de tu historia, acompañándote en los momentos cotidianos y
          en las celebraciones especiales. Nos emociona la idea de que nuestras
          creaciones se conviertan en parte de tu hogar, aportando un aire de
          elegancia y una sensación de calidez que perdurará en el tiempo.
        </p>
        <p className="lead text-justify">
          En resumen, Damcreation es el resultado de la pasión, el compromiso y
          el deseo de llevar la belleza artesanal a cada hogar. Agradecemos cada
          elección que haces al seleccionar nuestros productos y estamos
          encantados de formar parte de tu vida, ofreciendo calidad, elegancia y
          el encanto especial que solo los detalles bien cuidados pueden
          brindar.
        </p>
        {/*
        <h2 className="text-center py-4">
          Conoce algúnos de nuestros productos
        </h2>
        <div className="row">
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-100">
              <img
                className="card-img-top img-fluid"
                src="https://images.pexels.com/photos/298863/pexels-photo-298863.jpeg?auto=compress&cs=tinysrgb&w=600"
                alt=""
                height={160}
              />
              <div className="card-body">
                <h5 className="card-title text-center">Mens's Clothing</h5>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-100">
              <img
                className="card-img-top img-fluid"
                src="https://images.pexels.com/photos/7679720/pexels-photo-7679720.jpeg?auto=compress&cs=tinysrgb&w=600"
                alt=""
                height={160}
              />
              <div className="card-body">
                <h5 className="card-title text-center">Women's Clothing</h5>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-100">
              <img
                className="card-img-top img-fluid"
                src="https://images.pexels.com/photos/1927259/pexels-photo-1927259.jpeg?auto=compress&cs=tinysrgb&w=600"
                alt=""
                height={160}
              />
              <div className="card-body">
                <h5 className="card-title text-center">Jewelery</h5>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-100">
              <img
                className="card-img-top img-fluid"
                src="https://images.pexels.com/photos/356056/pexels-photo-356056.jpeg?auto=compress&cs=tinysrgb&w=600"
                alt=""
                height={160}
              />
              <div className="card-body">
                <h5 className="card-title text-center">Electronics</h5>
              </div>
            </div>
          </div>
        </div>
 */}
      </div>
      <Footer />
    </>
  );
};

export default AboutPage;
