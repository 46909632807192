import React from "react";

function ModalMesesSinIntereses() {
  return (
    <div
      className="modal fade modal-lg modal-dialog-scrollable"
      id="modal-meses-sin-intereses"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Meses sin intereses
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h5>
              Tarjetas participantes
              <i className="fa-solid fa-credit-card mx-3 text-secondary-dam"></i>
            </h5>
            <table className="table ">
              <tbody>
                <tr>
                  <td>afirmar</td>
                  <td>American Express</td>
                  <td>BanBajío</td>
                </tr>
                <tr>
                  <td>Bancopel</td>
                  <td>Banjercito</td>
                  <td>BBVA</td>
                </tr>
                <tr>
                  <td>Banca Mifel</td>
                  <td> Banco Azteca</td>
                  <td>Banco Famsa</td>
                </tr>
                <tr>
                  <td>Banco Invex</td>
                  <td>Banco Multiva </td>
                  <td>Banorte</td>
                </tr>
                <tr>
                  <td>Banregio</td>
                  <td>Citibanamex </td>
                  <td>falabella</td>
                </tr>
                <tr>
                  <td>Hola Banco</td>
                  <td>HSBC </td>
                  <td>Inbursa</td>
                </tr>
                <tr>
                  <td>Konfio</td>
                  <td>Liverpool </td>
                  <td>nanopago</td>
                </tr>
                <tr>
                  <td>Nubank</td>
                  <td> RappiCard</td>
                  <td>Santander</td>
                </tr>
                <tr>
                  <td></td>
                  <td> Scotiabank</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <h5 className="my-3">
              Montos mínimos aplicables
              <i className="fa-solid fa-circle-dollar-to-slot  text-secondary-dam mx-3"></i>
            </h5>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Plazo</th>
                  <th scope="col">Monto mínimo de compra</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>3 meses</td>
                  <td>$ 300.00 pesos</td>
                </tr>
                <tr>
                  <td>6 meses</td>
                  <td> $ 600.00 pesos </td>
                </tr>
                <tr>
                  <td>9 meses</td>
                  <td> $ 900.00 pesos</td>
                </tr>
                <tr>
                  <td>12 meses</td>
                  <td> $ 1,200.00 pesos </td>
                </tr>
                <tr>
                  <td>18 meses</td>
                  <td> $ 1,800.00 pesos</td>
                </tr>
                <tr>
                  <td>24 meses</td>
                  <td>$ 2,400.00 pesos </td>
                </tr>
                <tr>
                  <td></td>
                  <td> </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="modal-footer">
            <div className="row text-center">
              <p className="text-muted">
                <small>
                  *Los montos y tarjetas participantes podrían cambiar en
                  cualquier momento.
                </small>
              </p>
            </div>
            <button
              type="button"
              data-dismiss="modal"
              className="btn btn-secondary-dam"
            >
              De acuerdo
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalMesesSinIntereses;
