import React, { useState, useEffect, useContext } from "react";
import { Footer, Navbar } from "../components";
import { Link } from "react-router-dom";
import { createUsuario, loginUsuario } from "./../API/utilities";
import { TokenContext } from "./../Context/TokenContext";
import Usuario from "../components/Usuario";

const Register = () => {
  const { token, usuario, actualizarUsuario } = useContext(TokenContext);
  const [formValues, setFormValues] = useState({
    nombre: "",
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [buttonEnable, setButtonEnable] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  useEffect(() => {
    validarCampos();
  }, [formValues]);

  const validarCampos = () => {
    // Validar el campo de correo
    const correoValido = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formValues.email);

    // Validar la contraseña (al menos 8 caracteres)
    const passwordValido = formValues.password.length >= 8;
    if (formValues.nombre.length === 0) {
      setError("Ingresa un nombre con el que quieras que te llamemos.");
      setButtonEnable(false);
    } else if (!correoValido) {
      setError("Ingresa un correo electrónico válido.");
      setButtonEnable(false);
    } else if (!passwordValido) {
      setError("La contraseña debe tener al menos 8 caracteres.");
      setButtonEnable(false);
    } else {
      setError("");
      setButtonEnable(true);
    }
  };

  const handleSubmita = async (event) => {
    event.preventDefault();
    // para enviar los datos o hacer lo que necesites con ellos
    if (buttonEnable) {
      createUsuario(
        formValues.email,
        formValues.password,
        formValues.nombre,
        token
      )
        .then(async (response) => {
          if (response.status == 201) {
            const Respuesta2 = await loginUsuario(
              formValues.email,
              formValues.password
            );

            if (!(Respuesta2 == "-1")) actualizarUsuario(Respuesta2);
          } else {
            setError(response.message);
          }
        })
        .catch((error) => {
          setError(error.response.data.message);
        });
    }
  };

  return (
    <>
      <Navbar />
      {usuario.user._id == "" ? (
        <div className="container my-3 py-3">
          <h1 className="text-center">Registro</h1>
          <hr />
          <div className="row my-4 h-100">
            <div className="col-md-4 col-lg-4 col-sm-8 mx-auto">
              <form onSubmit={handleSubmita}>
                <div className="form my-3">
                  <label htmlFor="Name">Nombre completo</label>
                  <input
                    type="email"
                    className="form-control"
                    name="nombre"
                    id="Name"
                    value={formValues.nombre}
                    onChange={handleChange}
                    placeholder="Ingresa tu nombre"
                  />
                </div>
                <div className="form my-3">
                  <label htmlFor="Email">Correo electrónico</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="Email"
                    placeholder="Correo electrónico"
                    value={formValues.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="form  my-3">
                  <label htmlFor="Password">Escribe tu password</label>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    id="Password"
                    value={formValues.password}
                    onChange={handleChange}
                    placeholder="Password"
                  />
                </div>
                <div className="my-3">
                  <p>
                    ¿Ya tienes una cuenta?{" "}
                    <Link
                      to="/login"
                      className="text-decoration-underline text-info"
                    >
                      Iniciar Sesion
                    </Link>{" "}
                  </p>
                </div>
                <div className="text-center">
                  {buttonEnable ? (
                    <button
                      onClick={handleSubmita}
                      className="my-2 mx-auto btn btn-dark"
                      type="submit"
                    >
                      Registrarse
                    </button>
                  ) : (
                    <button className="my-2 mx-auto btn btn-dark" disabled>
                      Registrarse
                    </button>
                  )}
                  <p className="text-danger text-center mx-auto md-8 ">
                    {error}
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Usuario />
        </>
      )}
      <Footer />
    </>
  );
};

export default Register;
