import React from "react";
import ReactDOM from "react-dom/client";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./Styles/general.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { TokenProvider } from "./Context/TokenContext"; // Importa el TokenProvider

import {
  Home,
  Product,
  Products,
  AboutPage,
  ContactPage,
  Cart,
  Login,
  Register,
  Checkout,
  PageNotFound,
  SuccessPage,
  AvisoPage,
} from "./pages";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <TokenProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/product" element={<Products />} />
        <Route path="/product/:id" element={<Product />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/Success/:id" element={<SuccessPage />} />
        <Route path="/Aviso" element={<AvisoPage />} />
        <Route path="/product/*" element={<PageNotFound />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </TokenProvider>
  </BrowserRouter>
);
