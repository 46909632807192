import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { TokenContext } from "./../Context/TokenContext";
import "./../Styles/Navbar.css";

const Navbar = () => {
  const { itemsCarrito, usuario } = useContext(TokenContext);

  return (
    <>
      <div
        className="alert alert-secondary alert-dismissible fade show py-2 mb-0 d-flex justify-content-center align-items-center bg-primary-dam"
        id="id-div-navbar"
        role="alert"
      >
        <i className="fa-solid fa-truck-fast mr-2"></i>
        En la compra de tus artículos, &nbsp;
        <strong> EL ENVÍO ES GRATIS</strong>
        <span className="text-secondary-dam mx-2">
          <strong>{process.env.REACT_APP_ENVIRONMENT}</strong>
        </span>
        <button
          type="button"
          className="close pt-1 pr-2 text-white"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <nav className="navbar navbar-expand-lg  bg-black py-1 sticky-top ">
        <div className="container">
          <NavLink className="navbar-brand fw-bold fs-4 px-2" to="/">
            <img
              src="https://damcreation.com/assets/Logos/Logo-Legal-W.png"
              className="ImgLogo m-0 d-none d-lg-block"
              alt="Damcreation Corte y grabado laser"
            />

            <img
              src="https://damcreation.com/assets/Logos/Logo-Horizontal-W.png"
              className="ImgLogo m-0 d-block d-lg-none"
              alt="Damcreation Corte y grabado laser"
            />
          </NavLink>
          <button
            className="navbar-toggler mx-2  text-secondary-dam"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="fa-solid fa-bars"></i>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav m-auto my-2 text-center ">
              <li className="nav-item">
                <NavLink
                  className="nav-link text-white hover-secondary-dam"
                  to="/product"
                >
                  Productos
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link text-white hover-secondary-dam"
                  to="/about"
                >
                  Acerca de Nosotros
                </NavLink>
              </li>
              {/*  <li className="nav-item">
                <NavLink
                  className="nav-link text-white hover-secondary-dam"
                  to="/contact"
                >
                  Contacto
                </NavLink>
              </li> */}
            </ul>
            <div className="buttons text-center">
              {usuario.user._id == "" ? (
                <NavLink
                  to="/login"
                  className="btn btn-outline-secondary-dam m-2 text-secondary-dam"
                >
                  <i className="fa fa-sign-in-alt mr-1"></i> Iniciar sesión
                </NavLink>
              ) : (
                <NavLink
                  to="/login"
                  className="btn btn-outline-secondary-dam m-2 text-secondary-dam"
                >
                  <i className="fa fa-user-circle-o mr-1"></i> Bienvenido{" "}
                  {usuario.user?.nombre}
                </NavLink>
              )}

              {/* <NavLink to="/register" className="btn btn-outline-dark m-2">
              <i className="fa fa-user-plus mr-1"></i> Registro
            </NavLink> */}
              <NavLink
                to="/cart"
                className="btn btn-outline-secondary-dam m-2 text-secondary-dam"
              >
                <i className="fa fa-cart-shopping mr-1"></i> Carrito (
                {itemsCarrito}){" "}
              </NavLink>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
