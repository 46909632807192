import React from "react";

const SectionPayment = () => {
  return (
    <div className="container my-2  ">
      <div className="row justify-content-center">
        <div className="col-sm border border-white text-center pt-2 bg-primary-dam">
          <i className="fa-solid fa-lock fa-xl mt-4 text-secondary-dam"></i>
          <p className="py-2 text-secondary-dam">Pague cómodo y seguro</p>
        </div>
        <div className="col-6 col-lg border border-white text-center pt-2 bg-primary-dam">
          <i className="fa-solid fa-cart-shopping fa-xl mt-4"></i>
          <p className="py-2">Agregue sus productos al carrito</p>
        </div>
        <div className="col-6 col-lg border border-white text-center pt-2 bg-primary-dam">
          <i className="fa-solid fa-clipboard-check fa-xl mt-4"></i>
          <p className="py-2">Elija la dirección de envío</p>
        </div>
        <div className="col-6 col-lg border border-white text-center pt-2 bg-primary-dam">
          <i className="fa-solid fa-credit-card fa-xl mt-4"></i>
          <p className="py-2">
            Pague con tarjeta <br />
            <span className="text-muted">
              {" "}
              <a
                href="#"
                data-toggle="modal"
                data-target="#MesesSinIntereses"
                className="small text-decoration-none text-white"
              >
                *A meses sin intereses
              </a>
            </span>
          </p>
        </div>
        <div className="col-6 col-lg border border-white text-center pt-2 bg-primary-dam">
          <i className="fa-solid fa-truck-fast fa-xl mt-4"></i>
          <p className="py-2">Personalización de su producto y envío gratis</p>
        </div>
      </div>
      <div
        className="modal fade"
        id="MesesSinIntereses"
        tabindex="-1"
        aria-labelledby="MesesSinIntereses"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Pago a meses sin intereses
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              *Para poder acceder a meses sin intereses es necesario contar un
              con monto mínimo de compra
            </div>
            <div className="modal-footer">
              <button
                type="button"
                data-dismiss="modal"
                className="btn btn-secondary"
              >
                Aceptar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionPayment;
