import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Footer, Navbar } from "../components";
import { loginUsuario } from "./../API/utilities";
import { TokenContext } from "./../Context/TokenContext";
import Usuario from "../components/Usuario";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [buttonEnable, setButtonEnable] = useState(false);
  const { actualizarUsuario, usuario, nuevoUsuario } = useContext(TokenContext);

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    validateFields(newEmail, password);
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    validateFields(email, newPassword);
  };

  const validateFields = (newEmail, newPassword) => {
    // Validación del email (puede utilizar una expresión regular más compleja para una validación más completa)
    const emailIsValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail);

    // Validación de la contraseña (mínimo 8 caracteres)
    const passwordIsValid = newPassword.length >= 8;

    // Si ambos campos son válidos, habilitar el botón
    if (emailIsValid && passwordIsValid) {
      setButtonEnable(true);
      setError("");
    } else {
      setButtonEnable(false);
      setError(
        "Por favor, ingresa un correo válido y una contraseña con al menos 8 carácteres."
      );
    }
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault(); // Evitar la recarga de la página
      // Llamar a la función de inicio de sesión aquí
      const Respuesta = await loginUsuario(email, password);
      if (Respuesta == "-1") setError("Usuario y/o contraseña incorrectos");
      else {
        console.log("Entra al login correcto");
        console.log("res: ", Respuesta);
        Respuesta.user = { ...nuevoUsuario.user, ...Respuesta.user };
        console.log("Nueva Respuesta: ", Respuesta);
        actualizarUsuario(Respuesta);
      }
    } catch (error) {
      // Manejo de errores
      //console.error("Error en el inicio de sesión:", error);
    }
  };

  return (
    <>
      <Navbar />
      {usuario.user._id == "" ? (
        <>
          <div className="container my-3 py-3">
            <h1 className="text-center">Iniciar Sesión</h1>

            <hr />
            <div className="row my-4 h-100">
              <div className="col-md-4 col-lg-4 col-sm-8 mx-auto">
                <form onSubmit={handleSubmit}>
                  <div className="my-3">
                    <label htmlFor="display-4">Correo electrónico</label>
                    <input
                      type="email"
                      className="form-control"
                      id="txt_email"
                      value={email}
                      onChange={handleEmailChange}
                      placeholder="correo@ejemplo.com"
                    />
                  </div>
                  <div className="my-3">
                    <label htmlFor="floatingPassword display-4">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      value={password}
                      onChange={handlePasswordChange}
                      id="txt_password"
                      placeholder="Password"
                    />
                  </div>
                  <div className="my-3">
                    <p>
                      ¿No tienes cuenta?{" "}
                      <Link
                        to="/register"
                        className="text-decoration-underline text-secondary-dam"
                      >
                        ¡Regístrate!, es super sencillo
                      </Link>{" "}
                    </p>
                  </div>
                  <div className="text-center">
                    {buttonEnable ? (
                      <button
                        //   onClick={() => loginUsuario(email, password)}
                        className="my-2 mx-auto btn btn-secondary-dam"
                        type="submit"
                      >
                        Ingresar a mi cuenta
                      </button>
                    ) : (
                      <button
                        className="my-2 mx-auto btn btn-primary-dam"
                        disabled
                      >
                        Ingresar a mi cuenta
                      </button>
                    )}

                    <p className="text-danger text-center mx-auto md-8 ">
                      {error}
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Usuario />
        </>
      )}

      <Footer />
    </>
  );
};

export default Login;
