import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { Navbar, Footer } from "../components";
import { GetCheckout } from "./../API/utilities";
import Img from "./../Img/Gracias.jpg";
import { TokenContext } from "./../Context/TokenContext";

const PageNotFound = () => {
  const { saveToLocalStorage, CleanCart } = useContext(TokenContext);
  let { id } = useParams();

  const [nombre, setNombre] = useState("");

  useEffect(() => {
    const ObtenInfo = async () => {
      try {
        const _Info = await GetCheckout(id);
        setNombre(_Info);
        saveToLocalStorage("products", []);
        CleanCart();
      } catch (error) {
        //console.error("Error fetching products:", error);
      }
    };
    ObtenInfo();
  }, []);

  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center text-capitalize">
          ¡Muchas gracias {nombre}!{" "}
        </h1>
        <hr />
        <div className="row">
          <div className="col-md-12 py-5 bg-light text-center">
            <h2 className="p-1">Tu compra ha sido exitosa. </h2>
            <img src={Img} alt="Corte y grabado laser Dam Creation" />

            <h5 className="font-weight-normal text-justify mt-5">
              En estos momentos comenzaremos todos los preparativos para
              enviarte tu(s) productos al domicilio que nos hayas indicado.
            </h5>

            <h5 className="font-weight-normal text-justify my-5">
              También queremos agradecerte por confiar en nosotros y permitirnos
              seguir haciendo crecer este pequeño sueño, ahora ya eres parte de
              <b> Damcreation</b> y como recompensa no nos queda más que crearte
              un gran producto que sabemos te encantará y siempre con la mejor
              calidad posible.
            </h5>

            <h5 className="font-weight-normal text-justify my-5">
              Te haremos llegar un correo con todas las instrucciones necesarias
              en caso de que tu producto sea personalizado, pero si tienes
              alguna duda o quisieras conocer el estatus de tu compra, siempre
              puedes contactarnos por nuestros medios de comunicación.
            </h5>

            <h5 className="font-weight-normal  my-5">
              Que tengas un gran día y seguirémos en contacto.
            </h5>

            <Link to="/" className="btn  btn-outline-dark mx-4">
              <i className="fa fa-arrow-left"></i> Explorar mas productos
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PageNotFound;
