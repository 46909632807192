import React, { useEffect, useState, useContext } from "react";
import Skeleton from "react-loading-skeleton";
import { Link, useParams } from "react-router-dom";
import Marquee from "react-fast-marquee";
import { TokenContext } from "./../Context/TokenContext";
import { getProductId, getProducts } from "./../API/utilities";
import { Footer, Navbar } from "../components";
import CardItem from "../components/Molecules/CardItem";
import ModalMesesSinIntereses from "../components/Molecules/ModalMesesSinIntereses";

const Product = () => {
  const { token, updateToken, addProduct } = useContext(TokenContext);
  const { id } = useParams();
  const [product, setProduct] = useState([]);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mainImage, setMainImage] = useState("");
  const [loading2, setLoading2] = useState(true);
  const [divRealizarPedido, setDivRealizarPedido] = useState(false);
  const [divTiempoEntrega, setDivTiempoEntrega] = useState(false);
  const [divDescripcion, setDivDescripcion] = useState(true);

  const _addProduct = (product) => {
    addProduct(product);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const _producto = await getProductId(token, id);

        setProduct(_producto);
        if (_producto?.imagenes.length > 0) setMainImage(_producto.imagenes[0]);
        setLoading(false);

        /* const _productosSimilares = await getProducts(
          token,
          "?limit=15&page=1"
        ); 
        setSimilarProducts(_productosSimilares);
         if (_productosSimilares.length > 1) setLoading2(false); //Obteniendo categorías
        */
      } catch (error) {
        //console.error("Error fetching products:", error);
      }
    };

    fetchData();
  }, [token]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const _producto = await getProductId(token, id);

        setProduct(_producto);
        console.log(_producto);
        if (_producto?.imagenes.length > 0) setMainImage(_producto.imagenes[0]);
        setLoading(false);

        /* const _productosSimilares = await getProducts(
          token,
          "?limit=15&page=1"
        ); 
        setSimilarProducts(_productosSimilares);
         if (_productosSimilares.length > 1) setLoading2(false); //Obteniendo categorías
        */
      } catch (error) {
        //console.error("Error fetching products:", error);
      }
    };
    const scrollToDiv = () => {
      const element = document.getElementById("id-div-navbar");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };

    fetchData();
    scrollToDiv();
  }, []);

  const Loading = () => {
    return (
      <>
        <div className="container my-5 py-2">
          <div className="row">
            <div className="col-md-6 py-3">
              <Skeleton height={400} width={400} />
            </div>
            <div className="col-md-6 py-5">
              <Skeleton height={30} width={250} />
              <Skeleton height={90} />
              <Skeleton height={40} width={70} />
              <Skeleton height={50} width={110} />
              <Skeleton height={120} />
              <Skeleton height={40} width={110} inline={true} />
              <Skeleton className="mx-3" height={40} width={110} />
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Navbar />
      <div className="container">
        <div className="row">
          {loading ? (
            <Loading />
          ) : (
            <>
              <div className="container my-3 py-1">
                <div className="row p-0">
                  <div className="col-md-6 col-sm-12 py-1 text-center">
                    <img
                      className="img-fluid border"
                      src={mainImage}
                      alt={product.nombre}
                      title={product.nombre}
                      width="400px"
                      height="400px"
                    />
                    <div className="row d-flex justify-content-center mt-3 border-top">
                      <div className="col">
                        {product.imagenes.map((imagen, index) => (
                          <img
                            key={index} // Asegúrate de proporcionar una key única para cada elemento en el array
                            className="img-fluid border m-2 border-secondary btn p-0 rounded-0 img-product"
                            src={imagen}
                            title={product.nombre}
                            alt={product.nombre}
                            width="50px"
                            height="50px"
                            onClick={() => setMainImage(imagen)}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-md-6 p-4 rounded border ">
                    <Link to={"/"} className="btn mb-3 pt-0 ml-0 pl-0">
                      <i
                        class="fa-solid  fa-arrow-left-long text-secondary-dam mr-5 fa-xl c-pointer "
                        alt="Regresar al inicio"
                        title="Regresar al Inicio"
                      ></i>
                    </Link>
                    <div className="d-flex flex-row  mb-1">
                      <p className=" font-weight-lighter mr-4">DAMCREATION</p>
                      <p className="badge badge-info text-wrap bg-secondary-dam">
                        {product.categoria}
                      </p>
                    </div>

                    <h1 className="display-6 mb-3 font-weight-bold">
                      {product.nombre}
                    </h1>

                    <p className="lead mt-1 mb-5">
                      <i className="fa fa-star mx-1 text-secondary-dam"></i>
                      <i className="fa fa-star mx-1 text-secondary-dam"></i>
                      <i className="fa fa-star mx-1 text-secondary-dam"></i>
                      <i className="fa fa-star mx-1 text-secondary-dam"></i>
                      <i className="fa fa-star mx-1 text-secondary-dam"></i>
                      {"  "} {product.calificacion} {"  "}
                      {"("}
                      {product.opiniones} {")"}
                    </p>

                    <div className="d-flex flex-row  mt-3 mb-2">
                      <h3 className="display-8">${product.precio} MXN</h3>
                      <button
                        className=" btn btn-secondary-dam text-secondary-dam  ml-sm-4 ml-3 p-2 py-0"
                        onClick={() => _addProduct(product)}
                      >
                        <i class="fa-solid fa-cart-plus mx-2"></i>
                        Agregar al carrito
                      </button>
                    </div>
                    <p className=" mt-1 mb-5">
                      <i className="fa-solid mx-1 fa-thumbs-up text-success"></i>
                      <i className="fa-solid mx-1 fa-truck-fast text-success"></i>
                      El costo del envío corre por cuenta nuestra.
                    </p>

                    <p
                      className="mt-5 mb-2 font-weight-bold"
                      onClick={() => setDivTiempoEntrega(!divTiempoEntrega)}
                    >
                      Tiempo de envío
                      {divTiempoEntrega ? (
                        <i className="fa-solid fa-circle-chevron-up mx-2 c-pointer text-secondary-dam"></i>
                      ) : (
                        <i className="fa-solid fa-circle-chevron-down mx-2 c-pointer text-black"></i>
                      )}
                    </p>
                    {divTiempoEntrega && (
                      <p className="text-justify mb-4">
                        Nosotros tardamos de 2 a 4 días en preparar tu producto
                        a la medida y personalizado. <br /> <br />
                        La paquetería tardará aproximadamente de 3 a 5 días para
                        entregarlo. <br /> <br />
                        En caso de que te urja tu pedido, por favor háznoslo
                        saber y así intentar priorizar que tu compra salga en
                        menos tiempo. También, existe la opción de que pagues la
                        diferencia del envío express y pueda llegarte en menos
                        tiempo. Con gusto te guiaremos durante todo el proceso.
                      </p>
                    )}
                    <p
                      className="mt-3 mb-2 font-weight-bold"
                      onClick={() => setDivRealizarPedido(!divRealizarPedido)}
                    >
                      ¿Cómo realizar un pedido?
                      {divRealizarPedido ? (
                        <i className="fa-solid fa-circle-chevron-up mx-2 c-pointer text-secondary-dam"></i>
                      ) : (
                        <i className="fa-solid fa-circle-chevron-down mx-2 c-pointer text-black"></i>
                      )}
                    </p>
                    {divRealizarPedido && (
                      <p className="text-justify mb-4">
                        Para realizar tu pedido puedes hacerlo en nuestra tienda
                        en línea siempre y cuando quieras pagar con alguna
                        tarjeta bancaria. <br /> <br />
                        El proceso de pago es seguro y mediante la página del
                        mismo banco, nosotros no guardamos ningún dato de tus
                        tarjetas. Después del pago, te haremos llegar un ID de
                        Compra para dar seguimiento a tu pedido, nosotros nos
                        comunicaremos inmediatamente para saber el tipo de
                        personalización de tu producto. <br />
                        <br />
                        Si deseas pagar haciendo una transferencia, puedes
                        contactarnos en nuestro chat o al correo:
                        <b> contacto@damcreation.com </b> y con mucho gusto te
                        guiaremos.
                      </p>
                    )}
                    <p
                      className="mt-3 mb-2 font-weight-bold"
                      onClick={() => setDivDescripcion(!divDescripcion)}
                    >
                      Descripción del producto
                      {divDescripcion ? (
                        <i className="fa-solid fa-circle-chevron-up mx-2 c-pointer text-secondary-dam"></i>
                      ) : (
                        <i className="fa-solid fa-circle-chevron-down mx-2 c-pointer text-black"></i>
                      )}
                    </p>
                    {divDescripcion &&
                      product.descripcion
                        .split("\n\n")
                        .map((parrafo, index) => (
                          <p key={index} className="text-justify">
                            {parrafo}
                          </p>
                        ))}

                    <p className="mt-3 mb-2 font-weight-bold">
                      ¿Te interesa pagar a meses sin intereses?{" "}
                      <a
                        href="#"
                        className="alert-link text-secondary-dam hover-black"
                        data-toggle="modal"
                        data-target="#modal-meses-sin-intereses"
                      >
                        Descubre más
                      </a>
                    </p>

                    <div className="mt-5">
                      <Link
                        to="/cart"
                        className="d-block d-md-none btn btn-primary-dam"
                      >
                        Ir al carrito
                      </Link>
                      <button
                        className="d-block col-sm my-3 btn btn-secondary-dam text-secondary-dam  p-2 "
                        onClick={() => _addProduct(product)}
                      >
                        <i class="fa-solid fa-cart-plus mx-2"></i>
                        Agregar al carrito
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <ModalMesesSinIntereses />
            </>
          )}
        </div>
        {/* <div className="row my-5 py-5">
          <div className="d-none d-md-block">
            <h2 className="">Quizá tambien te podría interesar </h2>
            <Marquee pauseOnHover={true} pauseOnClick={true} speed={50}>
              {loading2 ? (
                <Loading2 />
              ) : (
                <>
                  <div className="py-4 my-4">
                    <div className="d-flex ">
                      {similarProducts.map((producto) => {
                        return (
                          <div className="col-md-4 col-sm-6 col-xs-8 col-12 mx-2">
                            <CardItem product={producto} />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </Marquee>
          </div>
        </div> */}
      </div>
      <Footer />
    </>
  );
};

export default Product;
