import React, { useState, useEffect, useContext } from "react";
import { TokenContext } from "./../../Context/TokenContext";

import "react-loading-skeleton/dist/skeleton.css";

import { Link } from "react-router-dom";

function CardItem({ product }) {
  const { addProduct } = useContext(TokenContext);

  const _addProduct = (product) => {
    addProduct(product);
  };

  const estiloContenedor = {
    backgroundImage: `url('${product.imagenes[0]}')`, // Reemplaza con la ruta de tu imagen
    backgroundSize: "cover", // Hace que la imagen cubra todo el contenedor
    backgroundPosition: "center", // Centra la imagen desde todos los lados
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <div
      id={product._id}
      key={product._id}
      className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4 "
    >
      <div className="card text-center h-100 shadow " key={product._id}>
        <div
          class="card-header bg-black p-0 m-0 contenedor-img-card"
          style={estiloContenedor}
        ></div>

        <div className="card-body">
          <h5 className="card-title text-wrap">{product.nombre}</h5>
        </div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item lead">
            $ {product.precio.toFixed(2)} MXN
          </li>
        </ul>
        <div className="card-body d-flex justify-content-center">
          <Link
            to={"/product/" + product._id}
            className="btn btn-primary-dam m-1"
          >
            <i class="fa-regular fa-eye mx-1"></i>
            Detalle
          </Link>
          <button
            className="btn  m-1 btn-secondary-dam"
            onClick={() => _addProduct(product)}
          >
            <i class="fa-solid fa-cart-plus mx-2"></i>
            Agregar
          </button>
        </div>
      </div>
    </div>
  );
}

export default CardItem;
