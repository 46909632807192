import React from "react";
import SectionPayment from "./Molecules/SectionPayment";

const Home = () => {
  return (
    <>
      <div className="hero border-1 pb-3">
        <div className="card bg-dark text-white border-0 mx-0">
          <img
            className="card-img img-fluid"
            src="./assets/main.jpg"
            alt="Card"
            height={500}
          />
        </div>
      </div>
      <SectionPayment />
    </>
  );
};

export default Home;
