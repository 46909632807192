import React, { useState, useEffect, useContext } from "react";
import { TokenContext } from "./../Context/TokenContext";

import { getProducts } from "./../API/utilities";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { Link } from "react-router-dom";
import CardItem from "./Molecules/CardItem";

const Products = () => {
  const { token, updateToken, addProduct } = useContext(TokenContext);
  const [productos, setProductos] = useState([]);
  const [loading, setLoading] = useState(true);

  const _addProduct = (product) => {
    addProduct(product);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const _productos = await getProducts(token);

        setProductos(_productos);
        if (_productos.length > 1) setLoading(false);
      } catch (error) {
        //console.error("Error fetching products:", error);
      }
    };
    fetchData();
  }, [token]);

  const Loading = () => {
    return (
      <>
        <div className="col-12 py-5 text-center">
          <Skeleton height={40} width={560} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
      </>
    );
  };

  return (
    <>
      <div className="container my-3 py-3">
        <div className="row mb-5">
          <div className="col-12">
            <h2 className="text-center">Tu Hogar, Tu Estilo</h2>
            <h6 className=" text-center">Cada producto es personalizable</h6>
          </div>
        </div>
        <div className="row justify-content-center">
          {loading ? (
            <Loading />
          ) : (
            productos.map((product) => {
              return <CardItem product={product} />;
            })
          )}
        </div>
      </div>
    </>
  );
};

export default Products;
