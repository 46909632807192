import React, { useContext } from "react";
import { Footer, Navbar } from "../components";
import { TokenContext } from "./../Context/TokenContext";
import CardResumen from "../components/Molecules/CardResumen";
import CardEmptyCart from "../components/Molecules/CardEmptyCart";

const Cart = () => {
  const { products, deleteItem, addProduct } = useContext(TokenContext);

  const ShowCart = () => {
    let subtotal = 0;
    let totalItems = 0;
    products.map((item) => {
      return (subtotal += item.precio * item.CantidadPedido);
    });

    products.map((item) => {
      totalItems = totalItems + item.CantidadPedido;
      return totalItems;
    });
    return (
      <>
        <section className="h-100 gradient-custom">
          <div className="container py-1">
            <div className="row d-flex justify-content-center my-4">
              <div className="col-md-8">
                <div className="card mb-4">
                  <div className="card-header py-3 bg-black text-white">
                    <h5 className="mb-0">Lista de artículos</h5>
                  </div>
                  <div className="card-body">
                    {products.map((item) => {
                      return (
                        <div key={item.id}>
                          <div className="row d-flex align-items-center">
                            <div className="col-lg-3 col-md-12">
                              <div
                                className="bg-image rounded"
                                data-mdb-ripple-color="light"
                              >
                                <img
                                  src={item.imagenes[0]}
                                  className="w-100 img-resumen"
                                  alt={item.nombre}
                                />
                              </div>
                            </div>

                            <div className="col-lg-5 col-md-6">
                              <p className="text-center mt-2 mt-sm-0">
                                <strong>{item.nombre}</strong>
                              </p>
                            </div>

                            <div className="col-lg-4 col-md-6 ">
                              <div className="d-flex mb-4 justify-content-center">
                                <button
                                  className="btn"
                                  onClick={() => deleteItem(item)}
                                >
                                  {item.CantidadPedido == 1 ? (
                                    <i className="fas fa-trash-o"></i>
                                  ) : (
                                    <i class="fa-solid fa-circle-minus"></i>
                                  )}
                                </button>

                                <div className="d-flex mx-1 mx-sm-2 justify-content-center flex-column text-center">
                                  <p className="text-muted mb-0">Cantidad</p>
                                  <p className="font-weight-bold mt-0">
                                    {item.CantidadPedido}
                                  </p>
                                </div>

                                <button
                                  className="btn"
                                  onClick={() => {
                                    addProduct(item);
                                  }}
                                >
                                  <i class="fa-solid fa-circle-plus text-secondary-dam"></i>
                                </button>
                              </div>

                              <p className="text-center ">
                                <strong>
                                  <span className="text-muted">
                                    {item.CantidadPedido}
                                  </span>{" "}
                                  x ${item.precio.toFixed(2)} MXN
                                </strong>
                              </p>
                            </div>
                          </div>

                          <hr className="my-3" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <CardResumen />
            </div>
          </div>
        </section>
      </>
    );
  };

  return (
    <>
      <Navbar />
      <div className="container my-2">
        <h1 className="text-center">Tu Carrito</h1>
        <hr />
        {products.length > 0 ? <ShowCart /> : <CardEmptyCart />}
      </div>
      <Footer />
    </>
  );
};

export default Cart;
