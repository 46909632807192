import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const [año, setAño] = useState("");

  useEffect(() => {
    const fechaActual = new Date();
    const añoActual = fechaActual.getFullYear();
    setAño(añoActual.toString());
  }, []);
  return (
    <>
      <footer className="text-center bg-primary-dam">
        <div className="container p-4">
          <h6>Síguenos en nuestras redes sociales:</h6>
          <section className="mb-3">
            <a
              data-mdb-ripple-init
              className="btn btn-outline btn-floating m-1"
              href="https://www.facebook.com/damcreation/"
              role="button"
              title="Facebook Damcreation | Regalos personalizados | Tu hogar, tu estilo "
              target="_blank" // Agregar este atributo
            >
              <i className="fab fa-facebook-f text-secondary-dam"></i>
            </a>

            <a
              data-mdb-ripple-init
              className="btn btn-outline btn-floating m-1"
              href="https://www.instagram.com/damcreationmx1/"
              role="button"
              title="Instagram Damcreation | Regalos personalizados | Tu hogar, tu estilo "
              target="_blank" // Agregar este atributo
            >
              <i className="fab fa-instagram text-secondary-dam"></i>
            </a>

            <a
              data-mdb-ripple-init
              className="btn btn-outline btn-floating m-1"
              href="https://www.tiktok.com/@damcreationmx"
              target="_blank" // Agregar este atributo
              title="TikTok Damcreation | Regalos personalizados | Tu hogar, tu estilo "
              role="button"
            >
              <i className="fa-brands fa-tiktok text-secondary-dam"></i>
            </a>
          </section>

          <section className="my-2">
            <div className="row">
              <div className="col-lg-4 col-md-12 mb-4 mb-md-0">
                <h5 className="text-uppercase">Páginas de ayuda</h5>

                <ul className="list-unstyled mb-1">
                  <li>
                    <NavLink
                      to="/Aviso"
                      className="link-offset-2 link-underline link-underline-opacity-10 link-underline-opacity-75-hover text-secondary-dam hover-white"
                    >
                      Aviso de Privacidad
                    </NavLink>
                  </li>
                  <li>
                    <a
                      className="link-offset-2 link-underline link-underline-opacity-10 link-underline-opacity-75-hover text-secondary-dam hover-white"
                      href="#!"
                    >
                      Preguntas Frecuentes
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-4 col-md-12 mb-4 mb-md-0">
                <h5 className="text-uppercase">Contácto</h5>

                <ul className="list-unstyled mb-0">
                  <li>
                    <a
                      className="link-offset-2 link-underline link-underline-opacity-10 link-underline-opacity-75-hover text-secondary-dam hover-white"
                      href="mailto:contacto@damcreation.com"
                    >
                      <i class="fa-solid fa-envelope text-secondary-dam"></i>{" "}
                      contacto@damcreation.com
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-4 col-md-12 mb-4 mb-md-0">
                <h5 className="text-uppercase">Visítanos en Mercado Libre</h5>

                <ul className="list-unstyled mb-0">
                  <li>
                    <a
                      className="link-offset-2 link-underline link-underline-opacity-10 link-underline-opacity-75-hover text-secondary-dam hover-white"
                      target="_blank"
                      href="https://listado.mercadolibre.com.mx/_CustId_690195347?item_id=MLM2771357038&category_id=MLM167545&seller_id=690195347&client=recoview-selleritems&recos_listing=true"
                    >
                      Tienda Damcreation
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>
        <section className="mb-4">
          <p>
            <i class="fa-solid fa-heart text-secondary-dam mr-2"></i>
            Cada producto está personalizado y hecho a la medida. Un toque de
            Elegancia; Tu hogar, Tu Estilo.{" "}
          </p>
          <p>
            <i class="fa-solid fa-message mr-2"></i> Contactanos y resolveremos
            todas tus dudas.
          </p>
        </section>
        <div className="text-center p-3">
          © {año} Copyright:
          <a
            className="text-reset fw-bold mx-2 link-offset-2 link-underline link-underline-opacity-0 "
            href="https://damcreation.com/"
          >
            damcreation.com
          </a>
          <i class="fa-solid fa-circle-chevron-down mx-2"></i>
        </div>
      </footer>
    </>
  );
};

export default Footer;
