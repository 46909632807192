import React, { useContext, useState, useEffect } from "react";
import { Footer, Navbar } from "../components";
import { TokenContext } from "./../Context/TokenContext";
import { createCheckout } from "./../API/utilities";
import CardEmptyCart from "../components/Molecules/CardEmptyCart";
import CardResumen from "../components/Molecules/CardResumen";

const Checkout = () => {
  const { products, usuario } = useContext(TokenContext);
  console.log(usuario.user);
  const [usuarioLocal, setUsuarioLocal] = useState(usuario.user);
  const [MensajeValidacion, setMensajeValidacion] = useState("");

  const [enableButton, setEnableButton] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setUsuarioLocal({
      ...usuarioLocal,
      [name]: value,
    });
  };

  const validarCorreo = (correo) => {
    const correoRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Expresión regular para validar correo electrónico

    return correoRegex.test(correo); // Devuelve true si el correo coincide con el patrón, de lo contrario devuelve false
  };

  const validarTelefono = (telefono) => {
    // Verificar si el número tiene 10 dígitos y todos son números
    return telefono.length === 10 && !isNaN(telefono);
  };

  const validarCP = (CP) => {
    // Verificar si el número tiene 10 dígitos y todos son números
    return CP.length === 5 && !isNaN(CP);
  };

  const ValidaInformacion = () => {
    if (usuarioLocal.nombre.length < 3)
      setMensajeValidacion(
        "Por favor escriba el nombre de la persona que recibirá el pedido."
      );
    else if (usuarioLocal.apellidos.length < 3)
      setMensajeValidacion(
        "Por favor escriba el apellido de la persona que recibirá el pedido."
      );
    else if (usuarioLocal.correo.length < 3)
      setMensajeValidacion(
        "Por favor escriba el correo de la persona que recibirá el pedido."
      );
    else if (!validarCorreo(usuarioLocal.correo)) {
      setMensajeValidacion("Por favor ingrese un correo electrónico válido.");
    } else if (usuarioLocal.teléfono.length < 10)
      setMensajeValidacion(
        "Por favor escriba el teléfono de la persona que recibirá el pedido."
      );
    else if (!validarTelefono(usuarioLocal.teléfono)) {
      setMensajeValidacion(
        "Por favor ingrese un número de teléfono válido de 10 dígitos."
      );
    } else if (usuarioLocal.calle.length === 0)
      setMensajeValidacion(
        "Por favor escriba la calle donde enviaremos  el pedido."
      );
    else if (usuarioLocal.códigoPostal.length < 5)
      setMensajeValidacion(
        "Por favor escriba el código postal donde enviaremos  el pedido."
      );
    else if (!validarCP(usuarioLocal.códigoPostal)) {
      setMensajeValidacion(
        "Por favor ingrese un códigoPostal válido de 5 dígitos."
      );
    } else if (usuarioLocal.colonia.length < 3)
      setMensajeValidacion(
        "Por favor escriba la colonia donde enviaremos  el pedido."
      );
    else if (usuarioLocal.ciudad.length < 3)
      setMensajeValidacion(
        "Por favor escriba la ciudad donde enviaremos  el pedido."
      );
    else if (usuarioLocal.noExterior.length === 0)
      setMensajeValidacion(
        "Por favor escriba el número exterior donde enviaremos  el pedido."
      );
    else {
      setMensajeValidacion("");
      setEnableButton(true);
    }
  };

  useEffect(() => {
    ValidaInformacion();
  }, [usuarioLocal]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // para enviar los datos o hacer lo que necesites con ellos
    createCheckout(usuarioLocal, products)
      .then(async (response) => {
        window.location.href = response.stripe_info.url;
      })
      .catch((error) => {});
  };

  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">Dirección de envío</h1>
        <hr />
        {products.length ? (
          <>
            <div className="container py-2">
              <div className="row my-2">
                <div className="col-md-8">
                  <div className="card mb-4">
                    <div className="card-header py-3 bg-black text-white">
                      <h4 className="mb-0">Información requerida para envío</h4>
                    </div>
                    <div className="card-body">
                      <form>
                        <div className="row g-3">
                          <h4 className="mb-3">Datos de contácto</h4>
                          <div className="col-sm-6 my-1">
                            <label htmlFor="firstName" className="form-label">
                              Nombre(s) *
                            </label>
                            <input
                              type="text"
                              name="nombre"
                              value={usuarioLocal.nombre}
                              onChange={handleChange}
                              className="form-control"
                              id="firstName"
                              placeholder=""
                              maxLength={90}
                            />
                          </div>

                          <div className="col-sm-6 my-1">
                            <label htmlFor="apellidos" className="form-label">
                              Apellido(s)*
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="apellidos"
                              placeholder=""
                              name="apellidos"
                              maxLength={90}
                              value={usuarioLocal.apellidos}
                              onChange={handleChange}
                            />
                          </div>

                          <div className="col-8 my-1">
                            <label htmlFor="correo" className="form-label">
                              Correo electrónico*
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="correo"
                              name="correo"
                              value={usuarioLocal.correo}
                              onChange={handleChange}
                              maxLength={90}
                            />
                          </div>

                          <div className="col-sm-4 my-1">
                            <label htmlFor="teléfono" className="form-label">
                              Número Celular*
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="teléfono"
                              name="teléfono"
                              maxLength={10}
                              value={usuarioLocal.teléfono}
                              onChange={handleChange}
                              placeholder=""
                            />
                          </div>
                        </div>
                        <hr className="my-4" />

                        <h4 className="mb-3">Dirección de entrega</h4>

                        <div className="row g-3 mt-3">
                          <div className="col-md-6 my-1">
                            <label htmlFor="calle" className="form-label">
                              Calle*
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="calle"
                              name="calle"
                              maxLength={50}
                              value={usuarioLocal.calle}
                              onChange={handleChange}
                              placeholder=""
                            />
                          </div>
                          <div className="col-md-3 my-1">
                            <label htmlFor="noExterior" className="form-label">
                              No. Ext.*
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="noExterior"
                              name="noExterior"
                              maxLength={20}
                              value={usuarioLocal.noExterior}
                              onChange={handleChange}
                              placeholder=""
                            />
                          </div>
                          <div className="col-md-3 my-1">
                            <label htmlFor="noInterior" className="form-label">
                              No. Int.
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="noInterior"
                              maxLength={20}
                              name="noInterior"
                              value={usuarioLocal.noInterior}
                              onChange={handleChange}
                              placeholder=""
                            />
                          </div>

                          <div className="col-md-4 my-1">
                            <label htmlFor="colonia" className="form-label">
                              Colonia*
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="colonia"
                              placeholder=""
                              maxLength={20}
                              name="colonia"
                              value={usuarioLocal.colonia}
                              onChange={handleChange}
                            />
                          </div>

                          <div className="col-md-4 my-1">
                            <label htmlFor="ciudad" className="form-label">
                              Ciudad/Municipio*
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="ciudad"
                              placeholder=""
                              maxLength={90}
                              name="ciudad"
                              value={usuarioLocal.ciudad}
                              onChange={handleChange}
                            />
                          </div>

                          <div className="col-md-4 my-1">
                            <label
                              htmlFor="códigoPostal"
                              className="form-label"
                            >
                              Código Postal*
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="códigoPostal"
                              placeholder=""
                              maxLength={5}
                              name="códigoPostal"
                              value={usuarioLocal.códigoPostal}
                              onChange={handleChange}
                            />
                          </div>

                          <div className="col-md-6 my-1">
                            <label htmlFor="country" className="form-label">
                              País
                            </label>
                            <br />
                            <select
                              readOnly
                              className="form-select"
                              id="country"
                            >
                              <option>México</option>
                            </select>
                          </div>
                        </div>

                        <hr className="my-4" />
                        <p className="text-danger my-3 text-center">
                          {MensajeValidacion}
                        </p>

                        {enableButton ? (
                          <button
                            type="button"
                            data-toggle="modal"
                            data-target="#exampleModalCenter"
                            className="w-100 btn btn-secondary-dam "
                          >
                            Pasar a caja a pagar
                          </button>
                        ) : (
                          <button
                            disabled
                            className="w-100 btn btn-secondary-dam "
                          >
                            Pasar a caja a pagar
                          </button>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
                <CardResumen textoBoton="Pagar" Proceso="2" />
              </div>
            </div>
            <div
              className="modal fade"
              id="exampleModalCenter"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <i className="fa-solid fa-lock mr-2 text-success"></i>

                    <h5
                      className="modal-title text-success"
                      id="exampleModalLongTitle"
                    >
                      Pago Seguro
                    </h5>
                    <button
                      type="button"
                      className="close "
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <p>
                      {" "}
                      Te redireccionaremos a un sitio seguro para que realices
                      el pago.
                    </p>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="btn btn-secondary-dam"
                    >
                      Continuar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <CardEmptyCart />
        )}
      </div>
      <Footer />
    </>
  );
};

export default Checkout;
