import { Navbar, Main, Product, Footer } from "../components";
import React, { useContext } from "react";
import { TokenContext } from "./../Context/TokenContext";

function Home() {
  // const { token, updateToken } = useContext(TokenContext);
  //
  // if (token === null) {
  //   updateToken();
  // }

  return (
    <>
      <Navbar />
      <Main />
      <Product />
      <Footer />
    </>
  );
}

export default Home;
