import React, { useContext, useState, useEffect } from "react";
import { TokenContext } from "./../Context/TokenContext";
import { modificaUsuario } from "./../API/utilities";

export default function Usuario() {
  const { usuario, actualizarUsuario, CleanUser } = useContext(TokenContext);
  const [usuarioLocal, setUsuarioLocal] = useState(usuario.user);
  const [enableButton, setEnableButton] = useState(false);
  const [mensajeFinal, setMensajeFinal] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;

    setUsuarioLocal({
      ...usuarioLocal,
      [name]: value,
    });
  };

  useEffect(() => {
    if (usuarioLocal.correo.length > 0 && usuarioLocal.nombre.length > 0)
      setEnableButton(true);
    else setEnableButton(false);
  }, [usuarioLocal]);

  const handleCheck = (event) => {
    if (event.target.checked) {
      setUsuarioLocal({
        ...usuarioLocal,
        direccionFacturacion: true,
        callefact: usuarioLocal.calle,
        noExteriorfact: usuarioLocal.noExterior,
        noInteriorfact: usuarioLocal.noInterior,
        coloniafact: usuarioLocal.colonia,
        ciudadfact: usuarioLocal.ciudad,
        codigoPostalfact: usuarioLocal.códigoPostal,
      });
    } else {
      setUsuarioLocal({
        ...usuarioLocal,
        direccionFacturacion: false,
        callefact: "",
        noExteriorfact: "",
        noInteriorfact: "",
        coloniafact: "",
        ciudadfact: "",
        codigoPostalfact: "",
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // para enviar los datos o hacer lo que necesites con ellos
    if (usuarioLocal.correo.length > 0 && usuarioLocal.nombre.length > 0) {
      modificaUsuario(usuarioLocal, usuario.Token)
        .then(async (response) => {
          if (response.status === 200) {
            setMensajeFinal("OK");
            actualizarUsuario({ ...usuario, user: { ...usuarioLocal } });
          }
        })
        .catch((error) => {});
    }
  };

  return (
    <>
      <div className="container py-5">
        <div className="row my-4">
          <div className="col-md-10 col-lg-8 mx-auto">
            <div className="card mb-4">
              <div className="card-header py-3 bg-black text-white">
                <h4 className="mb-0">
                  {usuarioLocal.nombre.length > 0 ? "Hola " : ""}{" "}
                  {usuarioLocal.nombre}
                </h4>
              </div>
              <div className="card-body">
                <form className="needs-validation" onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <h4 className="mb-3">Datos de contácto</h4>
                    <div className="col-sm-6 my-1">
                      <label htmlFor="firstName" className="form-label">
                        Nombre(s) *
                      </label>
                      <input
                        type="text"
                        name="nombre"
                        value={usuarioLocal.nombre}
                        onChange={handleChange}
                        className="form-control"
                        id="firstName"
                        placeholder=""
                        maxLength={90}
                        required
                      />
                      <div className="invalid-feedback">
                        Por favor ingresa tu nombre.
                      </div>
                    </div>

                    <div className="col-sm-6 my-1">
                      <label htmlFor="apellidos" className="form-label">
                        Apellido(s)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="apellidos"
                        placeholder=""
                        name="apellidos"
                        maxLength={90}
                        value={usuarioLocal.apellidos}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-8 my-1">
                      <label htmlFor="correo" className="form-label">
                        Correo electrónico*
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="correo"
                        name="correo"
                        value={usuarioLocal.correo}
                        onChange={handleChange}
                        required
                        maxLength={90}
                        readOnly
                      />
                      <div className="invalid-feedback">
                        Please enter a valid email address for shipping updates.
                      </div>
                    </div>

                    <div className="col-sm-4 my-1">
                      <label htmlFor="teléfono" className="form-label">
                        Número Celular
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="teléfono"
                        name="teléfono"
                        maxLength={10}
                        value={usuarioLocal.teléfono}
                        onChange={handleChange}
                        placeholder=""
                      />
                    </div>

                    <div className="col-md-6 my-1">
                      <label htmlFor="genero" className="form-label">
                        Género
                      </label>
                      <br />
                      <select
                        className="form-select"
                        id="genero"
                        name="genero"
                        value={usuarioLocal.genero}
                        onChange={handleChange}
                      >
                        <option>Otro</option>
                        <option>Hombre</option>
                        <option>Mujer</option>
                      </select>
                    </div>

                    <div className="col-md-6 my-1">
                      <label htmlFor="fechaNacimiento" className="form-label">
                        Fecha de nacimiento
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="fechaNacimiento"
                        name="fechaNacimiento"
                        maxLength={10}
                        value={usuarioLocal.fechaNacimiento}
                        onChange={handleChange}
                        placeholder=""
                      />
                    </div>
                  </div>

                  <hr className="my-4" />

                  <h4 className="mb-3">Dirección de entrega</h4>

                  <div className="row g-3 mt-3">
                    <div className="col-md-6 my-1">
                      <label htmlFor="calle" className="form-label">
                        Calle
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="calle"
                        name="calle"
                        maxLength={50}
                        value={usuarioLocal.calle}
                        onChange={handleChange}
                        placeholder=""
                      />
                    </div>
                    <div className="col-md-3 my-1">
                      <label htmlFor="noExterior" className="form-label">
                        No. Ext.
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="noExterior"
                        name="noExterior"
                        maxLength={20}
                        value={usuarioLocal.noExterior}
                        onChange={handleChange}
                        placeholder=""
                      />
                    </div>
                    <div className="col-md-3 my-1">
                      <label htmlFor="noInterior" className="form-label">
                        No. Int.
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="noInterior"
                        maxLength={20}
                        name="noInterior"
                        value={usuarioLocal.noInterior}
                        onChange={handleChange}
                        placeholder=""
                      />
                    </div>

                    <div className="col-md-4 my-1">
                      <label htmlFor="colonia" className="form-label">
                        Colonia
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="colonia"
                        placeholder=""
                        maxLength={20}
                        name="colonia"
                        value={usuarioLocal.colonia}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-md-4 my-1">
                      <label htmlFor="ciudad" className="form-label">
                        Ciudad/Municipio
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="ciudad"
                        placeholder=""
                        maxLength={90}
                        name="ciudad"
                        value={usuarioLocal.ciudad}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-md-4 my-1">
                      <label htmlFor="códigoPostal" className="form-label">
                        Código Postal
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="códigoPostal"
                        placeholder=""
                        maxLength={10}
                        name="códigoPostal"
                        value={usuarioLocal.códigoPostal}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-md-6 my-1">
                      <label htmlFor="country" className="form-label">
                        País
                      </label>
                      <br />
                      <select className="form-select" id="country">
                        <option>México</option>
                      </select>
                    </div>

                    <div className="col-md-6 my-1 text-right pt-5">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="direccionFacturacion"
                        name="direccionFacturacion"
                        value={usuarioLocal.direccionFacturacion}
                        onChange={handleCheck}
                      />
                      <label
                        htmlFor="direccionFacturacion"
                        className="form-label"
                      >
                        ¿Misma que dirección de facturación?
                      </label>
                    </div>
                    <div className="col-12 my-1">
                      <label htmlFor="referencia" className="form-label">
                        Referencia
                      </label>
                      <textarea
                        type="text"
                        rows="3"
                        className="form-control"
                        id="referencia"
                        placeholder="Danos una refencia para encontrar tu domicilio"
                        maxLength={200}
                        name="referencia"
                        value={usuarioLocal.referencia}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <hr className="my-4" />

                  <h4 className="mb-3">Dirección de facturación</h4>

                  <div className="row g-3 mt-3">
                    <div className="col-md-6 my-1">
                      <label htmlFor="callefact" className="form-label">
                        Calle
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="callefact"
                        maxLength={90}
                        name="callefact"
                        value={usuarioLocal.callefact}
                        onChange={handleChange}
                        placeholder=""
                      />
                    </div>
                    <div className="col-md-3 my-1">
                      <label htmlFor="noExteriorfact" className="form-label">
                        No. Ext.
                      </label>
                      <input
                        type="text"
                        maxLength={40}
                        className="form-control"
                        id="noExteriorfact"
                        name="noExteriorfact"
                        value={usuarioLocal.noExteriorfact}
                        onChange={handleChange}
                        placeholder=""
                      />
                    </div>
                    <div className="col-md-3 my-1">
                      <label htmlFor="noInteriorfact" className="form-label">
                        No. Int.
                      </label>
                      <input
                        type="text"
                        maxLength={40}
                        className="form-control"
                        id="noInteriorfact"
                        name="noInteriorfact"
                        value={usuarioLocal.noInteriorfact}
                        onChange={handleChange}
                        placeholder=""
                      />
                    </div>

                    <div className="col-md-4 my-1">
                      <label htmlFor="coloniafact" className="form-label">
                        Colonia
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="coloniafact"
                        maxLength={50}
                        placeholder=""
                        name="coloniafact"
                        value={usuarioLocal.coloniafact}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-md-4 my-1">
                      <label htmlFor="ciudadfact" className="form-label">
                        Ciudad/Municipio
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="ciudadfact"
                        maxLength={50}
                        placeholder=""
                        name="ciudadfact"
                        value={usuarioLocal.ciudadfact}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-md-4 my-1">
                      <label htmlFor="codigoPostalfact" className="form-label">
                        Código Postal
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="codigoPostalfact"
                        maxLength={60}
                        placeholder=""
                        name="codigoPostalfact"
                        value={usuarioLocal.codigoPostalfact}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-md-6 my-1">
                      <label htmlFor="countryfact" className="form-label">
                        País
                      </label>
                      <br />
                      <select className="form-select" id="countryfact">
                        <option>México</option>
                      </select>
                    </div>
                  </div>

                  <hr className="my-4" />

                  <button
                    className="w-100 btn btn-secondary-dam"
                    type="submit"
                    disabled={!enableButton}
                  >
                    Guardar la información
                  </button>

                  {mensajeFinal === "OK" ? (
                    <div
                      className="alert alert-success alert-dismissible fade show my-4"
                      role="alert"
                    >
                      <i className="fa-solid fa-heart-circle-check mr-2"></i>
                      Tu información ha sido actualizada!
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ) : mensajeFinal == "ERROR" ? (
                    <div
                      className="alert alert-warning  alert-dismissible fade show my-4"
                      role="alert"
                    >
                      <i className="fa-solid fa-heart-crack mr-2 "></i>
                      Lo sentimos, por el momento el servicio no esta
                      disponible.
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="col-md-12 mt-5 mx-0">
                    <button
                      type="button"
                      onClick={() => CleanUser()}
                      className="btn btn-outline-danger"
                    >
                      <i className="fa-solid fa-plug-circle-xmark mr-2"></i>
                      Cerrar sesión
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
